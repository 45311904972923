.landing-page-master {
  height: 100%;
  width: 100%;
  padding-left: 25px;
  padding-top: 25px;
  text-align: left;
}

.landing-page-text {
  font-size: 30px;
  padding-bottom: 20px;
  padding-left: 15px;
}

.landing-page-buttons {
  display: flex;
  justify-content: left;
  text-align: left;
  padding-left: 10px;
  flex-wrap: wrap;
  height: fit-content;
}

.lp-pill-button {
  width: 100%;

  //Marcelo
  font-family: "Jost", sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  letter-spacing: 1px !important;
  display: inline-block !important;
  padding: 10px 28px 11px 28px !important;
  border-radius: 50px !important;
  transition: 0.5s !important;
  margin: 10px 0 0 0 !important;
  color: #fff !important;
  background: #47b2e4 !important;
  box-shadow: 0px 15px 35px #47b2e4 !important;
  background: rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0px 5px 35px #47b2e4 !important;
  border: 2px solid #47b2e4 !important;
}

.lp-extra-button {
  //Marcelo
  font-family: "Jost", sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  letter-spacing: 1px !important;
  display: inline-block !important;
  padding: 10px 28px 11px 28px !important;
  border-radius: 50px !important;
  transition: 0.5s !important;
  margin: 10px 0 0 0 !important;
  color: #fff !important;
  background: #47b2e4 !important;
  box-shadow: 0px 15px 35px #47b2e4 !important;
  background: rgb(3, 134, 210) !important;
  box-shadow: 0px 5px 35px #47b2e4 !important;
  border: 2px solid #47b2e4 !important;
}

.url-copy-button {
  font-family: "Jost", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  border-radius: 0.375rem;
  transition: 0.5s;
  color: #f8f9fa;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #ffffff;
}

.url-copy-button:hover {
  color: #000000;
  background: rgba(255, 255, 255);
  border: 1px solid #ffffff;
}

.url-copy-button:disabled {
  color: #000000;
  background: rgba(255, 255, 255);
  border: 1px solid #ffffff;
}

.lp-pill {
  display: flex;
  flex-direction: column;
  width: 250px;
  min-height: 400px;
  transition: 0.5s;
  background: #0b1c2b;
  justify-content: space-between;
  box-shadow:
    inset 2px 2px 2px 2px rgb(0, 0, 0),
    10px 10px 10px -10px rgb(0, 0, 0);
  position: relative;
  z-index: 1;

  //Marcelo
  box-shadow: 0px 20px 30px black !important;
  border-radius: 15px !important;
  border: 2px solid rgba(0, 0, 0, 0.5) !important;
  margin: 10px !important;
  margin-bottom: 20px !important;
  padding: 30px !important;
  background-image: url(../../../public/pillBackground.jpg) !important;
  background-position: bottom;
}

.lp-pill-small {
  display: flex;
  flex-direction: column;
  width: 250px;
  min-height: 300px;
  transition: 0.5s;
  background: #0b1c2b;
  justify-content: space-between;
  box-shadow:
    inset 2px 2px 2px 2px rgb(0, 0, 0),
    10px 10px 10px -10px rgb(0, 0, 0);
  position: relative;
  z-index: 1;
  background-image: url(../../../public/pillBackground.jpg) !important;
  background-position: bottom;

  //Marcelo
  box-shadow: 0px 20px 30px black !important;
  border-radius: 15px !important;
  border: 2px solid rgba(0, 0, 0, 0.5) !important;
  margin: 10px !important;
  margin-bottom: 20px !important;
  padding: 30px !important;
}

.lp-pill-smallest {
  display: flex;
  flex-direction: column;
  width: 250px;
  min-height: 230px;
  transition: 0.5s;
  background: #0b1c2b;
  justify-content: space-between;
  box-shadow:
    inset 2px 2px 2px 2px rgb(0, 0, 0),
    10px 10px 10px -10px rgb(0, 0, 0);
  position: relative;
  z-index: 1;

  //Marcelo
  box-shadow: 0px 10px 15px black !important;
  border-radius: 15px !important;
  border: 2px solid rgba(0, 0, 0, 0.5) !important;
  margin: 10px !important;
  margin-bottom: 20px !important;
  padding: 30px !important;
}

.lp-pill-background {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}

.lp-pill-low-opacity {
  opacity: 0.2 !important;
}

.lp-pill-name {
  font-size: 25px;
  text-align: center;
  color: white;
  text-shadow:
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 10px 10px black;

  //Marcelo
  font-family: "Jost" !important;
  font-weight: 800 !important;
  padding-bottom: 30px !important;
}

.lp-pill-description {
  font-size: 15px;
  color: white;
  text-shadow:
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 10px 10px black;

  //Marcelo
  font-family: "Jost" !important;
}

.lp-pill:hover {
  box-shadow:
    1px 1px 1px 1px rgb(0, 0, 0),
    0px 10px 30px rgb(0, 0, 0);
  cursor: pointer;
}

.lp-title {
  font-size: 33px;
  font-weight: bold;
  text-align: center;
  font-family: "Jost" !important;
}

.lp-title-small {
  font-size: 28px;
  text-decoration: underline;
  text-align: center;
  font-family: "Jost" !important;
}

.landing-page-no-product-title {
  font-size: 30px;
}

.landing-page-no-product-text {
  font-size: 20px;
}
