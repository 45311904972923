.projections {
  padding: 10px;

  .projections-title {
    color: #ffffff;
    background-color: #0d608a;
    text-align: center;
  }

  .projections-input {
    padding: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .projections-label {
      grid-column: 1/2;
      width: 80px;
      padding-right: 5px;
    }

    .projections-input-box {
      grid-column: 2/3;

      .projections-input-box-input {
        width: 100px;
      }

      .css-1aia5sz-Slider {
        width: 100%;
      }
    }
  }
}
