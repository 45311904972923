.signup-text {
  font-size: 34px;
  margin-bottom: 40px;
}

.signup-button {
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.signup-errors {
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.signup-error-generic {
  background-color: rgb(255, 225, 90);
  border: 2px solid #000000;
}

.display-block {
  display: flex;
  flex-direction: column;
  background-color: #ffffffc8;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.error-popup-message {
  font-weight: bold;
  font-size: 25px;
  padding: 10px;
}

.display-none {
  display: none;
}

.confirmation-block {
  display: block;
  background-color: #ffffffe8;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding-top: 15%;
}

.confirmation-popup-message {
  padding: 10px;
}

.confirmation-none {
  display: none;
}

.signup-popup-big-text {
  padding: 15px;
}

.signup-popup-title {
  font-weight: bold;
  font-size: 35px;
}

.signup-popup-text {
  font-size: 20px;
}

.signup-popup-input {
  font-size: 20px;
}

.signup-popup-resend {
  font-size: 15px;
}

.signup-button {
  padding: 5px;
}

.modal-title {
  font-size: x-large;
  padding-bottom: 10px;
}

.modal-text {
  font-size: large;
  padding-bottom: 10px;
}

.error-popup-button {
  padding: 10px;
}

.validation-alert {
  font-size: larger;
  margin-bottom: 5px;
  font-weight: bold;
}
