@import "../../variables.module.scss";

.input-slider-master {
  justify-content: center;
  align-items: center;
}

.input-slider-slider {
  display: flex;
  align-items: center;
  height: 100%;
}

.input-slider-label {
  @media (max-width: $breakpoint-lg) {
    visibility: visible !important;
  }
  text-align: center;
  padding-top: 5px;
  writing-mode: vertical-rl;
  transform: scale(-1);
}
