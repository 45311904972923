@import "./variables.module.scss";
@import "../node_modules/react-pro-sidebar/dist/scss/styles.scss";

.pro-sidebar:not(collapsed) {
  position: absolute;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

// @font-face {
//   font-family: 'Abadi';
//   src: url("./assets/AbadiMTStd.otf") format("opentype");
// }

@mixin break-point {
  display: block;
}

body {
  @media (max-width: $breakpoint-xxs) {
    width: $breakpoint-xxs;
  }
}

.master {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 6fr 0.25fr;
  // font-family: 'Abadi';

  &.sm {
    grid-template-rows: auto 6fr 0.25fr;
  }

  .banner {
    grid-row: 1/1;
    display: flex;
    padding: 10px 20px;
    background-color: #0b1c2b;
    justify-content: space-between;

    .banner-text {
      font-size: 2em;
      margin: 20px 0;
      align-self: center;

      .title {
        text-decoration: none;
        color: white;
        text-shadow: 0px 15px 20px black;
        font-family: "Jost", sans-serif;
        letter-spacing: 2px;
        font-weight: 600;
      }
    }

    .banner-image {
      align-self: center;

      .logo {
        width: 60px;
        height: auto;
      }
    }

    .banner-user-profile {
      border: 1px solid #fff;
      border-radius: 5px;
    }

    .banner-user-profile-logo {
      color: #fff;
    }

    .banner-user-profile-name {
      color: #fff;
      font-size: 12px;
    }
  }

  .sidebar-lone-item-around-submenus {
    font-size: 17px;
    padding-left: 5px;
    display: flex;
  }

  .wrapper {
    grid-row: 2/3;
    display: flex;
    position: relative;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;

    .contains-sidebar {
      .sidebar-item {
        font-size: 17px;
      }
    }

    .sidebar-toggle-wrapper {
      display: none;
      position: fixed;
      margin-top: 50px;
      left: 10px;
      z-index: 99;

      &.xs {
        @media (max-width: $breakpoint-xs) {
          @include break-point;
        }
      }

      &.sm {
        @media (max-width: $breakpoint-sm) {
          @include break-point;
        }
      }

      &.md {
        @media (max-width: $breakpoint-md) {
          @include break-point;
        }
      }

      &.lg {
        @media (max-width: $breakpoint-lg) {
          @include break-point;
        }
      }

      &.xl {
        @media (max-width: $breakpoint-xl) {
          @include break-point;
        }
      }

      &.xxl {
        @media (max-width: $breakpoint-xxl) {
          @include break-point;
        }
      }

      .btn {
        box-shadow: 3px 3px 2px rgba(100, 100, 100);
      }
    }

    .contains-outlet {
      @media not all and (max-width: $breakpoint-lg) {
        padding-left: 75px;
      }

      flex-grow: 1;
      background-image: url(../public/dottedBackground.jpg);
      overflow-y: auto;
    }
  }

  .footer {
    grid-row: 3/4;
    padding: 2px;
    font-size: 12px;
    color: white;
    background-color: #0b1c2b;
    width: 100%;
    text-align: center;
  }
}

.generic-div {
  height: 99%;
  width: 100%;
  padding: 25px 0 0 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  @media (max-width: $breakpoint-md) {
    padding: 20px 0px;
  }

  .Description {
    padding: 0 20px;
    font-size: xx-large;
    height: fit-content;
  }
}

.loading-div {
  display: flex;
  height: 99%;
  width: 100%;
  padding: 25px 0 0 0;
  flex-direction: column;
  text-align: center;
  font-size: medium;
  font-weight: bold;
}

@media (max-width: 1470px) and (min-width: 1440px) {
  div[data-testid="tsEmbed"]:not(.ts-full-app) iframe {
    width: 1400px !important;
    margin-left: -35px !important;
  }
}

.responsive-centered-row {
  @media not all and (max-width: $breakpoint-lg) {
    display: flex;
    justify-content: center;
  }
}

body {
  font-family: "Jost", sans-serif !important;
}

.hero-image {
  color: white;
  height: 100%;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../public/landingPageBackground.jpg);
  background-position: center bottom;
  box-shadow: inset 0px 0px 40px rgba(0, 0, 0, 0.5);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex: 3 3 0;

  .hero-image h1 {
    color: var(--white);
    font-size: 2em;
    align-self: center;
  }
}

.site-slogan {
  text-shadow:
    0 15px 20px #000,
    0px 15px 50px rgba(120, 220, 250, 0.9) !important;
}

@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 3.5rem;
  }
}

.hero-image h1 {
  box-shadow: 0px 40px 90px #47b2e4;
  padding: 40px;
  padding-left: 80px;
  padding-right: 80px;
  border-radius: 20px;
}

h1 a {
  color: #47b2e4 !important;
}

h1 a:hover {
  color: #47c2f4 !important;
}

.btnSignUp {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px 11px 28px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  box-shadow: 0px 15px 35px #47b2e4;
  background: black;
  box-shadow: 0px 15px 35px #47b2e4;
  border: 2px solid #47b2e4;
}

.btnSignUp:hover {
  background: #209dd8;
}

@media (max-width: 768px) {
  #hero-image h1 {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding: 0px !important;
    box-shadow: none !important;
  }
}

@media (max-width: 575px) {
  #hero-image h1 {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding: 0px !important;
    box-shadow: none !important;
  }
}

.roundedCorners {
  background-color: white;
  padding: 30px;
  border-radius: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1) !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.signup-need-account {
  font-size: small;
}

.signup-end {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.display-block {
  display: block;
  background-color: #ffffffc8;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding-top: 15%;
}

.display-none {
  display: none;
}

.error-popup-message {
  font-weight: bold;
  font-size: 25px;
  padding: 10px;
}

//
//.ag-cell {
//  border-right: 1px solid #000000 !important;
//}

.btn-dark-rounded {
  padding: 12px 30px !important;
  border-radius: 50px !important;
  color: #fff !important;
  border: 2px solid #47b2e4 !important;
  font-weight: 600 !important;
  box-shadow: 0px 5px 20px #47b2e4 !important;
  background-color: rgba(0, 0, 0, 1) !important;
  margin: 10px !important;
}

.btn-dark-rounded:hover {
  opacity: 0.9 !important;
}

.btn-dark-rounded:active {
  opacity: 0.8 !important;
}

.center-aligned {
  display: flex;
  padding: 10px;
  justify-content: center;
  width: 100%;
}

.revolution-banner {
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 0) 0%,
    rgba(98, 70, 192, 0) 15%,
    rgba(28, 97, 217, 1) 38%,
    rgba(11, 76, 187, 1) 100%
  ) !important;
  border-bottom: 3px solid red;
}

.redirect-text {
  padding: 25px 0 0 0;
  flex-direction: column;
  text-align: center;
  font-size: larger;
  font-weight: bold;
}
