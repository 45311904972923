@import "../../variables.module.scss";

.multiple-input-sliders-master {
  text-align: center;
  padding: 10px;
}

.multiple-input-sliders-is-vertical {
  padding-top: 5px;
}

.multiple-input-sliders-is-horizontal {
  padding-top: 5px;
  @media not all and (max-width: $breakpoint-lg) {
    display: flex;
  }
}

.multiple-input-sliders-result-box {
  display: flex;
  align-items: center;
}

.individual-is {
  padding-left: 2px;
}

.multiple-input-sliders-result-label {
  @media (max-width: $breakpoint-lg) {
    visibility: visible !important;
  }
  text-align: center;
  padding-top: 5px;
  writing-mode: vertical-rl;
  transform: scale(-1);
}

.multiple-input-sliders-result-display {
  background: #d9d9d9;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: fit-content;
}

.multiple-input-sliders-column-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
