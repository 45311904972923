.ag-header-cell-label {
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
} //only way I've seen to change the orientation of the header

.income-statement-master {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  padding-left: 10px;
  padding-top: 25px;

  .income-statement-table-title {
    font-size: xx-large;
    width: 100%;
  }

  .income-statement-table-subtitle {
    font-size: small;
    height: fit-content;
    padding-bottom: 15px;
  }

  .income-statement-tables-div {
    width: 100%;
  }

  .income-statement-table {
    padding-bottom: 20px;
  }

  .text {
    text-align: right;

    font-size: medium;
  }

  .highlight {
    border-top: 3px;
    border-right: 0px;
    border-bottom: 0px;
    border-left: 0px;
    border-style: solid;
    background-color: rgb(157, 255, 255);
    font-weight: bold;
    font-size: medium;
  }

  .column {
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3px;
    border-left: 0px;
    border-style: solid;
    font-weight: bold;
    font-size: large;
  }
}
