.m-master {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.m-title {
  font-size: xx-large;
  height: fit-content;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.left {
  grid-column: 1, 2;
}

.right {
  grid-column: 2, 3;
  display: flex;
  max-width: fit-content;
}

.m-button {
  display: flex;
  padding: 5px;
  font-size: medium;
}

.derecha {
  grid-column: 2, 3;
}

.graphs {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.rows {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
