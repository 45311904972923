.error-title {
  font-size: larger;
  font-weight: bold;
}

.error-text {
  font-size: medium;
  padding: 5px;
}

.error-specifics {
  font-weight: bold;
}
