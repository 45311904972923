.ag-header-cell-label {
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
} //only way I've seen to change the orientation of the header

.sheet-master {
  background-color: #ffffff;
  padding-left: 10px;
  padding-top: 25px;
  height: 100%;
}

.sheet-table-title {
  font-size: xx-large;
  width: 100%;
}

.sheet-table-subtitle {
  font-size: small;
  height: fit-content;
  padding-bottom: 15px;
}

.text {
  text-align: right;

  font-size: medium;
}

.highlight {
  border-top: 2px;
  border-right: 0px;
  border-bottom: 0px;
  border-left: 0px;
  border-style: solid;
  background-color: rgb(157, 255, 255);
  font-weight: bold;
  font-size: medium;
}

.simple-highlight {
  background-color: rgb(244, 255, 169);
  font-weight: bold;
  font-size: medium;
}

.totals {
  border-top: 2px;
  border-right: 0px;
  border-bottom: 0px;
  border-left: 0px;
  border-style: solid;
  background-color: rgb(236, 255, 169);
  font-weight: bold;
  font-size: medium;
}

.separator {
  font-weight: bold;
  font-size: large;
}

.column {
  border-top: 0px;
  border-right: 0px;
  border-bottom: 3px;
  border-left: 0px;
  border-style: solid;
  font-weight: bold;
  font-size: large;
}

.sheet-tables-div {
  display: flex;
}

.sheet-table-container {
  width: 100%;
  padding: 5px;
}
