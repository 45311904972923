$transition-period: 0.75s;
.banner.sm {
  padding: 0px 10px;
  transition: all $transition-period ease-in;

  .banner-text {
    font-size: 1.5em;
    margin: 10px 0;
    transition: all $transition-period ease-in;
  }

  .banner-image {
    .logo {
      width: 40px;
      transition: width $transition-period ease-in;
    }
  }
}
