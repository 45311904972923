@import "../../variables.module.scss";

.module-master {
  background-color: #ffffff;
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
}

.module-title {
  font-size: xx-large;
  height: fit-content;
  padding-bottom: 10px;
}

.module-subtitle {
  font-size: small;
  height: fit-content;
  padding-left: 5px;
}

.module-row {
  @media not all and (max-width: $breakpoint-lg) {
    display: flex;
  }

  width: 100%;
}

.module-chart-container {
  padding: 2px;
  width: 100%;
}

.module-table-1 {
  height: fit-content;
  width: 100%;
  background-color: azure;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: large;

  .module-table-1-title {
    padding-left: 5px;
    font-size: x-large;
    width: 100%;
  }

  .module-table-1-contents {
    text-align: right;
    width: 100%;
    font-size: large;
  }

  .module-table-1-sub-title-big {
    font-size: large;
    text-decoration: underline;
  }

  .module-table-1-sub-title-small {
    font-size: medium;
    text-decoration: underline;
  }

  .module-table-1-subtotal {
    border-width: 2px 0px 0px 0px;
    border-style: solid;
    border-color: rgb(0, 0, 0);
  }

  .module-table-1-total {
    background-color: aquamarine;
    border-width: 2px 0px 2px 0px;
    border-style: solid;
    border-color: rgb(0, 0, 0);
  }

  .module-table-1-ebitda-top {
    border-width: 2px 0px 0px 0px;
    border-style: solid;
    border-color: rgb(0, 0, 0);
    background-color: rgb(255, 206, 127);
  }

  .module-table-1-ebitda-bottom {
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    border-color: rgb(0, 0, 0);
    background-color: rgb(255, 206, 127);
  }

  .module-table-1-ebita-titles {
    background-color: rgb(129, 173, 255);
  }

  .module-table-1-ebita-titles-top {
    background-color: rgb(129, 173, 255);
    border-width: 2px 0px 0px 0px;
    border-style: solid;
    border-color: rgb(0, 0, 0);
  }

  .module-table-1-ebita-titles-bottom {
    background-color: rgb(129, 173, 255);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    border-color: rgb(0, 0, 0);
  }

  .module-table-1-ebita-top {
    border-width: 2px 0px 0px 0px;
    border-style: solid;
    border-color: rgb(0, 0, 0);
    background-color: rgb(129, 173, 255);
  }

  .module-table-1-ebita-middle {
    background-color: rgb(129, 173, 255);
  }

  .module-table-1-ebita-bottom {
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    border-color: rgb(0, 0, 0);
    background-color: rgb(129, 173, 255);
  }
}

.module-input-sliders {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $breakpoint-lg) {
    flex-direction: column;
  }
}

.module-table-2-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.module-table-2-title {
  text-align: center;
  text-decoration: underline;
  font-size: x-large;
}

.module-table-2-subtitle {
  font-size: small;
  font-weight: bold;
  padding-left: 3px;
}

.module-center-table {
  display: flex;

  @media (max-width: $breakpoint-lg) {
    flex-wrap: wrap;
  }

  text-align: center;
  justify-content: center;
  align-items: center;
}

.module-table-2-align-right {
  text-align: right;
  padding-left: 10px;
}

.module-table-2-number {
  text-align: right;
}

.module-table-2-bottom-line {
  border-bottom: 3px;
  border-style: solid;
}

.module-table-2-blue {
  color: rgb(0, 124, 219);
}

.module-table-2-sum {
  background-color: rgb(157, 255, 255);
}

.module-financial-model-title {
  color: #ffffff;
  background-color: #0d608a;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.module-disable-if-mobile {
  @media (max-width: $breakpoint-lg) {
    display: none;
  }
}

.border-on-top {
  padding-bottom: 10px;
}

.border-on-top:before {
  content: "";
  display: block;
  margin: 0 auto; /* This will center the border. */
  width: 90%;
  border-bottom: 3px solid black;
}

.source-text {
  font-size: small;
  text-align: center;
}

.selector-title {
  font-weight: bold;
}

.input-error {
  border: 2px;
  border-style: solid;
  border-color: red;
}

.input-inline-error-message {
  font-size: small;
  text-align: center;
}

.option-in-card {
  border: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.279);
  border-radius: 5px;
  display: flex;
  padding: 15px;
  align-items: center;
  margin: 5px;
}

.table-highlight-green {
  background-color: rgb(66, 245, 114) !important;
  font-weight: bold;
}

.table-highlight-red {
  background-color: rgb(255, 115, 110) !important;
  font-weight: bold;
}

.table-highlight-yellow {
  background-color: rgb(255, 250, 110) !important;
}

.table-average {
  background-color: rgb(255, 145, 19) !important;
  font-weight: bold;
}

.table-total {
  background-color: rgb(66, 245, 108) !important;
  font-weight: bold;
}

.table-title {
  font-size: larger;
  text-align: center;
  font-weight: bold;
}

.width-min-130 {
  min-width: 130px;
}

.saving-to-file-modal-text {
  font-size: larger;
  padding: 40px;
}

.modal-disable-padding-top {
  padding-top: 0 !important;
}

.clicked-button {
  color: #ffffff !important;
  background-color: rgb(0, 0, 0) !important;
  font-weight: bold;
}

.grate-group {
  background-color: rgb(179, 181, 255) !important;
  font-weight: bold;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
}

.grate-column {
  background-color: rgb(108, 181, 230) !important;
  font-weight: bold;
  text-align: center;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
}

.ag-header-group-cell-label {
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

.grate-normal-text {
  text-align: center;
}

.Title {
  font-weight: bold;
  border-width: 2px 0px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  box-shadow: inset 0px -2px 0px 0px rgb(0, 0, 0);
}

.SubTitle {
  background-color: rgb(230, 185, 108) !important;
}

.SubTitleBlue {
  background-color: rgb(151, 238, 255) !important;
}

.SubTitlePurple {
  background-color: rgb(193, 160, 204) !important;
}

.SubTitleOrange {
  background-color: rgb(255, 219, 168) !important;
}

.SubTitleRed {
  background-color: rgb(255, 168, 168) !important;
}

.date-selector-title {
  font-weight: bold;
  font-size: large;
}

.bordered-object {
  border: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.279);
  border-radius: 5px;
  padding: 15px;
  align-items: center;
  margin: 5px;
  width: fit-content;
  height: fit-content;
}

.grid-title {
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
  font-size: larger;
}

.grid-bold {
  font-weight: bold;
}

.text-center-for-titles {
  text-align: center;
  font-size: xx-large;
  font-weight: bold;
}

.text-left {
  text-align: left;
  font-size: medium;
}

.text-center {
  text-align: center;
  font-size: medium;
}

.text-right {
  text-align: right;
  font-size: medium;
}

.text-underlined {
  box-shadow: inset 0px -1px 0px 0px rgb(0, 0, 0);
}

.dashboard-container {
  padding: 20px;
}

.row_demo {
  display: flex;
  margin: 10px;
  width: fit-content;
}

.chart-container {
  text-align: center;
  border: 3px;
  margin: 10px;
  border-color: #333;
  border-style: solid;
}

h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.maintenance-message {
  text-align: center;
  border: 3px;
  border-color: #333;
  border-style: solid;
  background-color: #ffff00;
}

.system-offline-message {
  padding-top: 20px;
  text-align: center;
  font-size: xx-large;
}
